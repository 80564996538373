import { motion, useMotionValue, useTransform } from 'framer-motion';
import React, { useEffect } from 'react';
import useMedia, { mediaBreakpoints } from '../../hooks/use-media';
import { useViewportIntersection } from '../../hooks/use-viewport-intersection';
import { StylableProp } from '../../utils/stylable-prop';
import PerspectiveCard from '../perspective-card';
import FeatureCard from './feature-card';
import * as style from './index.module.scss';

const { md, lg, xl } = mediaBreakpoints;

export interface FeatureCardsProps {
  buyingRate: number;
  refinanceRate: number;
  lineRate: number;
  loanRate: number;
}

export default function FeatureCards(props: StylableProp<FeatureCardsProps>) {
  const [cardWidth, cardHeight] = useMedia(
    [xl, lg, md],
    [
      [270, 350],
      [230, 320],
      [270, 350]
    ],
    [270, 350]
  );

  const viewportSize = useMedia<'small' | 'large'>([lg, md], ['large', 'small'], 'small');
  const [ref, ratio] = useViewportIntersection<HTMLDivElement>();

  const scrollPos = useMotionValue(0);
  const translateY = useTransform(scrollPos, [-1, 0], [-520, 0]);

  useEffect(() => scrollPos.set(ratio), [ratio]);

  return (
    <section className={`${style.featureCards} position-relative m-auto ${props.className}`}>
      <div
        ref={ref}
        className={`${style.cardContainer} container h-100 d-flex flex-column justify-content-center`}
      >
        <motion.div
          className={`${style.cardWrapper} position-relative d-flex align-items-center justify-content-center justify-content-lg-around`}
          style={{ translateY: viewportSize === 'large' ? translateY : 0 }}
        >
          <PerspectiveCard showHighlight={true}>
            <FeatureCard
              width={cardWidth}
              height={cardHeight}
              href="/buy"
              title="Purchase."
              caption="Today's Rate"
              value={props.buyingRate}
              description="Buying my Home"
              buttonLabel="Buy my Home"
            />
          </PerspectiveCard>

          <PerspectiveCard showHighlight={true}>
            <FeatureCard
              width={cardWidth}
              height={cardHeight}
              href="/refinance"
              title="Refinance."
              caption="HomeBoost Plan"
              value={props.refinanceRate}
              description="Lower Rate, Bigger Savings"
              buttonLabel="Boost My Savings"
            />
          </PerspectiveCard>

          <PerspectiveCard showHighlight={true}>
            <FeatureCard
              width={cardWidth}
              height={cardHeight}
              href="/line"
              title="Line of Credit."
              caption="HomeBoost Line"
              value={props.lineRate}
              description="Unlock your home equity"
              buttonLabel="Save More Money"
            />
          </PerspectiveCard>

          <PerspectiveCard showHighlight={true}>
            <FeatureCard
              width={cardWidth}
              height={cardHeight}
              href="/loan"
              title="Home Equity Loan."
              caption="Homeowner Plan"
              value={props.loanRate}
              description="Get lower rate & save more"
              buttonLabel="Unlock My Equity"
            />
          </PerspectiveCard>
        </motion.div>
      </div>
    </section>
  );
}
